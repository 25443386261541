<template>
    <FilterModal
        :isLoading="isLoading"
        @filter="getFilterData"
        @reset="resetFilter"
    >
        <template #filters>
            <Dropdown
                name="contestType"
                :title="filterTitles.contestType"
                :required="true"
                :options="filterOptions.contestType"
                v-model="filterPayload.contestType"
            />
            <Dropdown
                name="contestId"
                :title="filterTitles.contestId"
                :required="true"
                :options="filterOptions.contestId"
                v-model="filterPayload.contestId"
            />
            <Dropdown
                v-if="filterOptions.contestRule?.length > 0"
                name="contestRule"
                :title="filterTitles.contestRule"
                :required="true"
                :options="filterOptions.contestRule"
                v-model="filterPayload.contestRule"
            />
            <Dropdown
                name="year"
                :title="filterTitles.year"
                :required="true"
                :options="filterOptions.year"
                v-model="filterPayload.year"
            />
            <AccountRoleFilter
                ref="accountRoleFilter"
                :isUseTitleType="false"
                v-model:titleType="filterPayload.titleType"
                v-model:manCode="filterPayload.manCode"
                v-model:role="filterPayload.role"
                v-model:bgCode="filterPayload.bgCode"
            />
            <Dropdown
                name="dataType"
                :title="filterTitles.dataType"
                :required="true"
                :options="filterOptions.dataType"
                v-model="filterPayload.dataType"
            />
            <Dropdown
                name="relationMode"
                :title="filterTitles.relationMode"
                :required="true"
                :options="filterOptions.relationMode"
                v-model="filterPayload.relationMode"
            />
            <!-- TODO: 需與後端確認 min -->
            <Textbox
                v-if="filterPayload.contestId === 6"
                name="endDate"
                type="date"
                max="today"
                :onlyMonth="true"
                :isMonthLastDay="true"
                :required="true"
                :title="filterTitles.endDate"
                v-model="filterPayload.endDate"
            />
        </template>
    </FilterModal>
</template>
<script>
import AccountRoleFilter from '@/containers/AccountRoleFilter.vue'
import FilterModal from '@/containers/FilterModal.vue'
import Textbox from '@/components/Textbox.vue'
import Dropdown from '@/components/Dropdown.vue'
import { awardsFilterTitles } from '@/assets/javascripts/award/setting'
import { getAwardsCodesAPI } from '@/assets/javascripts/api.js'
import filterDefault from '@/assets/json/filterDefault.json'

export default {
    name: 'AwardsFilter',
    components: {
        AccountRoleFilter,
        FilterModal,
        Dropdown,
        Textbox
    },
    props: {
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        apiPermission: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    methods: {
        getFilterData() {
            const apiAlias = this.dropdownOptions.awards?.find(
                (data) => data.groupId === this.filterPayload.contestId
            ).apiAliasList
            if (
                !this.$isSameValue(
                    this.perviousFilterPayload,
                    this.filterPayload
                )
            ) {
                this.perviousFilterPayload = this.$cloneDeepValue(
                    this.filterPayload
                )
                this.$emit('update:apiPermission', apiAlias)
                this.$emit('show-data-table')
                this.filterLog = this.$getFilterLog(this.filterPayload)
            }

            this.hasCrossFilter = false
        },
        resetFilter() {
            this.$refs.accountRoleFilter.resetFilter()
            this.filterPayload = {
                year: this.yearNow,
                relationMode: this.filterOptions.relationMode[0]?.value,
                endDate: this.$formatDate(this.$getDate()),
                ...filterDefault.award
            }
            this.filterPayload = this.$getStorePayload(this.filterPayload)
        },
        getDropdownOptions: async function () {
            this.$showLoading()
            try {
                const res = await getAwardsCodesAPI()
                this.dropdownOptions = res.data
            } catch (error) {
                this.dropdownOptions = {}
                this.$showErrorMessage(
                    '載入時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            } finally {
                this.$hideLoading()
            }
        }
    },
    computed: {
        filterOptions() {
            const yearOption = []
            for (let i = this.minYear; i <= this.yearNow; i++) {
                yearOption.push({ label: i, value: i })
            }

            const optionSetting = this.dropdownOptions.awards?.find(
                (data) => data.groupId === this.filterPayload.contestId
            )

            const typeValid = this.dropdownOptions.awards?.map(
                (award) => award.type
            )

            return {
                contestType: this.dropdownOptions.awardTypeList
                    ?.map((data) => {
                        return { label: data.value, value: data.key }
                    })
                    .filter((data) => typeValid.includes(data.value)),
                contestId: this.dropdownOptions.awards
                    ?.map((data) => {
                        return {
                            label: data.name,
                            value: data.groupId,
                            type: data.type
                        }
                    })
                    .filter(
                        (data) => data.type === this.filterPayload.contestType
                    ),
                contestRule: this.dropdownOptions.awardRuleList
                    ?.filter((data) => {
                        return optionSetting?.ruleOptions?.includes(data.key)
                    })
                    .map((data) => {
                        return { label: data.value, value: data.key }
                    }),
                year: yearOption,
                dataType: this.dropdownOptions.dataTypeList
                    ?.filter((data) => {
                        return optionSetting?.dataTypeOptions?.includes(
                            data.key
                        )
                    })
                    .map((data) => {
                        return { label: data.value, value: data.key }
                    }),
                relationMode: this.dropdownOptions.relationModeList
                    ?.filter((data) => {
                        return optionSetting?.relationModeOptions?.includes(
                            data.key
                        )
                    })
                    .map((data) => {
                        return { label: data.value, value: data.key }
                    })
                    .filter((data) => {
                        return !(
                            this.filterPayload.role === 'ceo' &&
                            data.value === 'self'
                        )
                    })
            }
        }
    },
    watch: {
        filterPayload: {
            handler() {
                this.$emit('update:filterPayloadProp', this.filterPayload)
            },
            deep: true
        },
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        },
        'filterPayload.contestType': {
            handler() {
                const isIdValid = this.filterOptions.contestId.some(
                    (id) => this.filterPayload.contestId === id.value
                )
                if (!isIdValid)
                    this.filterPayload.contestId = this.filterOptions.contestId[0].value
            }
        },
        'filterPayload.role': {
            handler() {
                this.filterPayload.relationMode =
                    this.filterOptions.relationMode &&
                    this.filterOptions.relationMode[0].value
            }
        },
        'filterPayload.contestId': {
            handler() {
                if (this.filterOptions.contestRule.length > 0)
                    this.filterPayload.contestRule = this.filterOptions.contestRule[0].value
                this.filterPayload.dataType = this.filterOptions.dataType[0].value
                this.filterPayload.relationMode = this.filterOptions.relationMode[0].value
            }
        }
    },
    data() {
        return {
            filterPayload: this.filterPayloadProp,
            filterLog: this.filterLogProp,
            filterTitles: {},
            perviousFilterPayload: {},
            dropdownOptions: {},
            hasCrossFilter: this.$hasCrossFilter(),
            minYear: 2023,
            yearNow: new Date().getFullYear(),
            awardsName: {}
        }
    },
    mounted() {
        this.$nextTick(async () => {
            const accountRoleTitles = this.$refs.accountRoleFilter.filterTitles
            this.filterTitles = {
                ...accountRoleTitles,
                ...awardsFilterTitles,
                _seq: [...accountRoleTitles._seq, ...awardsFilterTitles._seq]
            }

            await this.getDropdownOptions()
            await this.resetFilter()
            if (this.hasCrossFilter) await this.getFilterData()
        })
    }
}
</script>
